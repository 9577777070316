.showTooltipOnHover:hover {
  border: 2px solid lightgreen;
}

.showTooltipOnHover button {
  display: none;
}

.showTooltipOnHover:hover button {
  display: block;
}

.element {
  border: 2px solid lightgreen;
}

.element h1 {
  text-align: center;
  margin-top: -10px;
  height: 20px;
  line-height: 20px;
  font-size: 15px;
}

.element h1 span {
  background-color: lightgreen;
}
