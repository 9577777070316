.dropZone {
  border-bottom: 2.5em solid transparent !important;
  background-image: repeating-linear-gradient(45deg, #ccc, #ccc 30px, #dbdbdb 30px, #dbdbdb 60px);
}

.emptyDropRow {
  height: 10px;
  background-color: yellow;
  border-radius: 10px;
}

.emptyDropColumn {
  width: 10px;
  background-color: yellow;
  height: 100%;
  border-radius: 10px;
}

.borderDashed:hover {
  border: thin dashed;
  border-radius: 10px;
}
