.title0 {
}

.title1 {
  border-bottom: 2px #bfe373 solid;
}

.paddingLeft {
  padding-left: 5px !important;
}

.paddingTopBottom0 {
  width: 100%;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
