.listElement {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.smallCheckbox {
  height: 24px !important;
}

.selectedInInitialAssessmentTest {
  background-color: yellow;
}
