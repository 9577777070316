.svgContainer {
  width: fit-content;
  margin: 0 auto;
}

.svgContainer svg,
.loader {
  height: 300px;
}

.parentDiv:hover .label {
  display: block;
}

.label {
  display: none;
  position: relative;
  left: 50%;
}

.label:hover {
  cursor: pointer;
}

input[type='checkbox']:checked + label .zoomIn {
  display: none;
}

input[type='checkbox']:not(:checked) + label .zoomOut {
  display: none;
}

input[type='checkbox']:checked ~ .svgContainer svg {
  height: 1000px;
}
